import { Box, SimpleGrid, Skeleton, Text, useColorModeValue } from '@chakra-ui/react'

export const GridFieldItem = ({
  label,
  children,
  ekeng,
  hasEkeng,
  ekengPrefix,
  isLoading = true,
  isReady = false,
  ...props
}) => {
  const themeBorderColor = useColorModeValue('gray.100', 'var(--chakra-colors-whiteAlpha-200)')
  const themeBg = useColorModeValue('gray.50', 'transparent')
  const themeColorFontEmpty = useColorModeValue('gray.400', 'gray.600')

  const additionalColumn = hasEkeng ? 1 : 0

  return (
    <SimpleGrid
      columns={label ? 2 + additionalColumn : 1 + additionalColumn}
      gap="16px"
      minHeight="46px"
      alignItems="center"
      borderBottomWidth="1px"
      borderColor={themeBorderColor}
      pl="16px"
      pr="16px"
      pt="8px"
      pb="8px"
      _even={{ bg: themeBg }}
      {...props}
    >
      {label && <Box>{label}</Box>}
      {isLoading || !isReady ? (
        <Skeleton borderRadius={8} height="12px" width="100px" />
      ) : (
        <>
          <Box wordBreak="break-all">
            {children || <Text color={themeColorFontEmpty}>Нет данных</Text>}
          </Box>
          {hasEkeng && (
            <Box wordBreak="break-all">
              {ekeng || <Text color={themeColorFontEmpty}>Нет данных</Text>}
            </Box>
          )}
        </>
      )}
    </SimpleGrid>
  )
}

import { Badge, Box, Button, Center, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import {
  ContentContainer,
  ContentContainerColumn,
  GridField,
  GridFieldHeading,
  GridFieldItem,
  RightAside,
} from 'chakra-lib/layout'
import { CountryWidget } from 'components/CountryWidget'
import { PrivateBox, getAccess } from 'components/Private/Private'
import isEmpty from 'lodash/isEmpty'
import {
  ROLES,
  TYPE_ENABLED_COLOR,
  TYPE_USER_COUNTRY,
  TYPE_USER_PLATFORM,
  getTypeStatus,
  getTypeStatusTitle,
  typeStatus,
  userIdentification,
  userIdentificationColor,
  userIdentificationLabel,
} from 'model/user.model'
import { useSelector } from 'react-redux'
import { getDateReadFormat, getDateTimeReadFormat } from 'utils/dateFormatting'
import { ActivateUser } from './ActivateUser/ActivateUser'
import { ApproveIdentificationModal } from './ApproveIdentificationModal/ApproveIdentificationModal'
import { ChangeUserIdentification } from './ChangeUserIdentification/ChangeUserIdentification'
import { DeclineIdentificationModal } from './DeclineIdentificationModal/DeclineIdentificationModal'
import { DisactivateUser } from './DisactivateUser/DisactivateUser'
import { RemoveUserModal } from './RemoveUserModal/RemoveUserModal'
import { ResetUserIdentification } from './ResetUserIdentification/ResetUserIdentification'
import { TrustUser } from './TrustUser/TrustUser'

export const User = ({ userId, data, isLoading }) => {
  const user = useSelector((state) => state.authentication.user)
  const isAdmin = getAccess(user.roles, [ROLES.admin])

  const status = getTypeStatus(data)
  const isReadyItem = !isEmpty(data)
  const hasEkeng = !!data?.ekengData

  return (
    <ContentContainer>
      <ContentContainerColumn>
        <GridField hasEkeng={hasEkeng}>
          {hasEkeng && (
            <SimpleGrid minHeight="40px" columns={3}>
              <Box />
              <Box />
              <Center fontWeight="bold">Ekeng</Center>
            </SimpleGrid>
          )}
          <GridFieldItem
            label="Имя"
            isLoading={isLoading}
            isReady={isReadyItem}
            ekeng={data?.ekengData?.englishFirstName}
            hasEkeng={hasEkeng}
          >
            {data?.firstName}
          </GridFieldItem>
          <GridFieldItem
            label="Фамилия"
            isLoading={isLoading}
            isReady={isReadyItem}
            ekeng={data?.ekengData?.englishLastName}
            hasEkeng={hasEkeng}
          >
            {data?.lastName}
          </GridFieldItem>
          <GridFieldItem
            label="Отчество"
            isLoading={isLoading}
            isReady={isReadyItem}
            hasEkeng={hasEkeng}
          >
            {data?.middleName}
          </GridFieldItem>
          <GridFieldItem
            label="Дата рождения"
            isLoading={isLoading}
            isReady={isReadyItem}
            ekeng={getDateReadFormat(data?.ekengData?.birthDate)}
            hasEkeng={hasEkeng}
          >
            {getDateReadFormat(data?.birthDate)}
          </GridFieldItem>
          <GridFieldItem
            label="ClientID"
            isLoading={isLoading}
            isReady={isReadyItem}
            hasEkeng={hasEkeng}
          >
            {data?.clientId}
          </GridFieldItem>
          {isAdmin && (
            <GridFieldItem
              label="Номер договора"
              isLoading={isLoading}
              isReady={isReadyItem}
              hasEkeng={hasEkeng}
            >
              {data?.contractNumber}
            </GridFieldItem>
          )}
          <GridFieldItem
            label="Номер счета"
            isLoading={isLoading}
            isReady={isReadyItem}
            hasEkeng={hasEkeng}
          >
            {data?.accountNumber}
          </GridFieldItem>
          <GridFieldItem
            label="Дата создания"
            isLoading={isLoading}
            isReady={isReadyItem}
            hasEkeng={hasEkeng}
          >
            {getDateTimeReadFormat(data?.createDate)}
          </GridFieldItem>
        </GridField>
        <GridField>
          <GridFieldHeading>Паспорт</GridFieldHeading>
          <GridFieldItem
            label="Паспорт"
            isLoading={isLoading}
            isReady={isReadyItem}
            ekeng={data?.ekengData?.documentNumber}
            hasEkeng={hasEkeng}
          >
            {data?.passport}
          </GridFieldItem>
          <GridFieldItem
            label="Выдан"
            isLoading={isLoading}
            isReady={isReadyItem}
            ekeng={data?.ekengData?.documentDepartment}
            hasEkeng={hasEkeng}
          >
            {data?.issue}
          </GridFieldItem>
          <GridFieldItem
            label="Дата выдачи"
            isLoading={isLoading}
            isReady={isReadyItem}
            ekeng={getDateReadFormat(data?.ekengData?.issuanceDate)}
            hasEkeng={hasEkeng}
          >
            {getDateReadFormat(data?.dateIssue)}
          </GridFieldItem>
          <GridFieldItem
            label="Дата окончания"
            isLoading={isLoading}
            isReady={isReadyItem}
            ekeng={getDateReadFormat(data?.ekengData?.validityDate)}
            hasEkeng={hasEkeng}
          >
            {null}
          </GridFieldItem>
        </GridField>
        <GridField>
          <GridFieldHeading>Адрес регистрации</GridFieldHeading>
          <GridFieldItem
            label="Страна"
            isLoading={isLoading}
            isReady={isReadyItem}
            ekeng={data?.ekengData?.countryName}
            hasEkeng={hasEkeng}
          >
            {data?.registrationAddress?.addressFields?.country}
          </GridFieldItem>
          <GridFieldItem
            label="Регион"
            isLoading={isLoading}
            isReady={isReadyItem}
            ekeng={data?.ekengData?.region}
            hasEkeng={hasEkeng}
          >
            {data?.registrationAddress?.addressFields?.regionName}
          </GridFieldItem>
          <GridFieldItem
            label="Тип региона"
            isLoading={isLoading}
            isReady={isReadyItem}
            hasEkeng={hasEkeng}
          >
            {data?.registrationAddress?.addressFields?.regionType}
          </GridFieldItem>
          <GridFieldItem
            label="Район"
            isLoading={isLoading}
            isReady={isReadyItem}
            hasEkeng={hasEkeng}
          >
            {data?.registrationAddress?.addressFields?.districtName}
          </GridFieldItem>
          <GridFieldItem
            label="Населенный пункт"
            isLoading={isLoading}
            isReady={isReadyItem}
            ekeng={`Община: ${data?.ekengData?.community}`}
            hasEkeng={hasEkeng}
          >
            {data?.registrationAddress?.addressFields?.settlementName}
          </GridFieldItem>
          <GridFieldItem
            label="Тип населенного пункта"
            isLoading={isLoading}
            isReady={isReadyItem}
            hasEkeng={hasEkeng}
          >
            {data?.registrationAddress?.addressFields?.settlementType}
          </GridFieldItem>
          <GridFieldItem
            label="Улица"
            isLoading={isLoading}
            isReady={isReadyItem}
            ekeng={data?.ekengData?.street}
            hasEkeng={hasEkeng}
          >
            {data?.registrationAddress?.addressFields?.streetName}
          </GridFieldItem>
          <GridFieldItem
            label="Тип улицы"
            isLoading={isLoading}
            isReady={isReadyItem}
            hasEkeng={hasEkeng}
          >
            {data?.registrationAddress?.addressFields?.streetType}
          </GridFieldItem>
          <GridFieldItem
            label="Дом"
            isLoading={isLoading}
            isReady={isReadyItem}
            ekeng={data?.ekengData?.building}
            hasEkeng={hasEkeng}
          >
            {data?.registrationAddress?.addressFields?.houseNumber}
          </GridFieldItem>
          <GridFieldItem
            label="Номер корпуса"
            isLoading={isLoading}
            isReady={isReadyItem}
            hasEkeng={hasEkeng}
          >
            {data?.registrationAddress?.addressFields?.block}
          </GridFieldItem>
          <GridFieldItem
            label="Номер квартиры"
            isLoading={isLoading}
            isReady={isReadyItem}
            ekeng={data?.ekengData?.apartment}
            hasEkeng={hasEkeng}
          >
            {data?.registrationAddress?.addressFields?.unitNumber}
          </GridFieldItem>
        </GridField>
        {(data?.inn || data?.snils || data?.ssn) && (
          <GridField>
            <GridFieldHeading>Дополнительные документы</GridFieldHeading>
            {data?.inn && (
              <GridFieldItem
                label="ИНН"
                isLoading={isLoading}
                isReady={isReadyItem}
                hasEkeng={hasEkeng}
              >
                {data?.inn}
              </GridFieldItem>
            )}
            {data?.snils && (
              <GridFieldItem
                label="Снилс"
                isLoading={isLoading}
                isReady={isReadyItem}
                hasEkeng={hasEkeng}
              >
                {data?.snils}
              </GridFieldItem>
            )}
            {data?.ssn && (
              <GridFieldItem
                label="TIN"
                isLoading={isLoading}
                isReady={isReadyItem}
                hasEkeng={hasEkeng}
              >
                {data?.ssn}
              </GridFieldItem>
            )}
          </GridField>
        )}
        <GridField>
          <GridFieldHeading>Идентификация</GridFieldHeading>
          <GridFieldItem
            label="Статус"
            isLoading={isLoading}
            isReady={isReadyItem}
            hasEkeng={hasEkeng}
          >
            {userIdentificationLabel[data?.identification]}
          </GridFieldItem>
          {data?.identificationError && (
            <GridFieldItem
              label="Код ошибки"
              isLoading={isLoading}
              isReady={isReadyItem}
              hasEkeng={hasEkeng}
            >
              {data?.identificationError}
            </GridFieldItem>
          )}
          <GridFieldItem
            label="Дата идентификации"
            isLoading={isLoading}
            isReady={isReadyItem}
            hasEkeng={hasEkeng}
          >
            {getDateTimeReadFormat(data?.identificationDate)}
          </GridFieldItem>
          <GridFieldItem
            label="Страна"
            isLoading={isLoading}
            isReady={isReadyItem}
            ekeng={data?.ekengData?.countryName}
            hasEkeng={hasEkeng}
          >
            <CountryWidget lang={data?.identityCountry}>
              {TYPE_USER_COUNTRY[data?.identityCountry]}
            </CountryWidget>
          </GridFieldItem>
        </GridField>
        <GridField>
          <GridFieldHeading>Приложение</GridFieldHeading>
          <GridFieldItem
            label="Версия приложения"
            isLoading={isLoading}
            isReady={isReadyItem}
            hasEkeng={hasEkeng}
          >
            {data?.applicationVersion}
          </GridFieldItem>
          <GridFieldItem
            label="Платформа"
            isLoading={isLoading}
            isReady={isReadyItem}
            hasEkeng={hasEkeng}
          >
            {TYPE_USER_PLATFORM[data?.platform]}
          </GridFieldItem>
        </GridField>
        <GridField>
          <GridFieldHeading>Контакты</GridFieldHeading>
          <GridFieldItem
            label="Телефон"
            isLoading={isLoading}
            isReady={isReadyItem}
            hasEkeng={hasEkeng}
          >
            {data?.phone}
          </GridFieldItem>
          <GridFieldItem
            label="E-mail"
            isLoading={isLoading}
            isReady={isReadyItem}
            hasEkeng={hasEkeng}
          >
            {data?.email}
          </GridFieldItem>
        </GridField>
        <GridField>
          <GridFieldHeading>Дополнительная информация</GridFieldHeading>
          <GridFieldItem
            label="ФССП арест"
            isLoading={isLoading}
            isReady={isReadyItem}
            hasEkeng={hasEkeng}
          >
            {data?.fsspArrested ? 'Да' : 'Нет'}
          </GridFieldItem>
          <GridFieldItem
            label="Добавлен в белый список"
            isLoading={isLoading}
            isReady={isReadyItem}
            hasEkeng={hasEkeng}
          >
            {data?.trusted ? 'Да' : 'Нет'}
          </GridFieldItem>
          <GridFieldItem
            label="Умер"
            isLoading={isLoading}
            isReady={isReadyItem}
            ekeng={data?.ekengData?.isDead ? 'Да' : 'Нет'}
            hasEkeng={hasEkeng}
          >
            {null}
          </GridFieldItem>
          <GridFieldItem
            alignItems="start"
            label="Комментарии"
            isLoading={isLoading}
            isReady={isReadyItem}
            hasEkeng={hasEkeng}
          >
            {data?.note
              ? String(data?.note)
                  .split('\n')
                  .map((item) => (
                    <>
                      <Text key={item} wordBreak="break-word">
                        {item}
                      </Text>
                      <br />
                    </>
                  ))
              : undefined}
          </GridFieldItem>
        </GridField>
      </ContentContainerColumn>
      <ContentContainerColumn>
        <Stack spacing="24px" position="sticky" top="10px">
          <RightAside>
            <Text fontSize="0.82rem" color="gray.400" mb={2}>
              Идентификация
            </Text>
            <Badge
              minH="50px"
              padding="1px 10px"
              borderRadius={5}
              fontSize="1rem"
              width="100%"
              textAlign="center"
              pt={3}
              pb={3}
              whiteSpace="none"
              colorScheme={userIdentificationColor[data?.identification] || 'gray'}
            >
              {userIdentificationLabel[data?.identification] || data?.identification}
            </Badge>
            {[userIdentification.SUPPORT_REVIEW, userIdentification.READY].includes(
              data?.identification
            ) && (
              <PrivateBox role={user.roles} hasAnyRole={[ROLES.admin, ROLES.user]}>
                <Stack spacing={3} pt="20px">
                  <ApproveIdentificationModal userId={userId} />
                  <DeclineIdentificationModal userId={userId} />
                </Stack>
              </PrivateBox>
            )}
            {data?.identification && (
              <PrivateBox role={user.roles} hasAnyRole={[ROLES.admin]}>
                <Stack spacing={3} pt="20px">
                  {[userIdentification.FULL, userIdentification.FULL_DOSSIER].includes(
                    data?.identification
                  ) && (
                    <ChangeUserIdentification
                      userId={userId}
                      initialValues={data}
                      isDisabled={isLoading}
                    />
                  )}
                  <ResetUserIdentification userId={userId} isDisabled={isLoading} />
                </Stack>
              </PrivateBox>
            )}
          </RightAside>
          <RightAside>
            <Text fontSize="0.82rem" color="gray.400" mb={2}>
              Активность
            </Text>
            <Badge
              padding="1px 10px"
              borderRadius={5}
              fontSize="1rem"
              width="100%"
              textAlign="center"
              pt={3}
              pb={3}
              whiteSpace="none"
              colorScheme={TYPE_ENABLED_COLOR[data?.enabled] || 'gray'}
            >
              {getTypeStatusTitle(data)}
            </Badge>
            <PrivateBox role={user.roles} hasAnyRole={[ROLES.admin]}>
              {!isLoading && (
                <Stack spacing={3} pt="20px">
                  {status === typeStatus.active && <DisactivateUser userId={userId} />}
                  {status === typeStatus.deactivated && <ActivateUser userId={userId} />}
                  {status !== typeStatus.blocked && <RemoveUserModal userId={userId} />}
                </Stack>
              )}
            </PrivateBox>
          </RightAside>
          <PrivateBox role={user.roles} hasAnyRole={[ROLES.admin]}>
            <RightAside>
              <Text fontSize="0.82rem" color="gray.400" mb={2}>
                Лимиты
              </Text>
              <TrustUser userId={userId} trusted={data.trusted} isDisabled={isLoading} />
            </RightAside>
          </PrivateBox>
          <Box>
            <Button
              width="100%"
              onClick={() => history.push(`/payments/payerPhone=${data?.phone}`)}
              variant="outline"
            >
              Отфильтровать в платежах
            </Button>
          </Box>
        </Stack>
      </ContentContainerColumn>
    </ContentContainer>
  )
}

